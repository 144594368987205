import styled from 'styled-components';
import { CFormRange } from '@coreui/react'

export const ReportContainer = styled.div`
  margin-top: -10px;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const ReportContainerOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageButtonReport = styled.div`
  height: 15px;
  width: 15px;
  fill: white;
`;

export const Report = styled.div`
  margin-top: 0px;
  height: calc(100dvh - 300px);
  min-height: 100%;
`;

export const Topbar = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export const TopbarLink = styled.a`
  color: var(--color-white);
  font-size: 18px;
  padding: 0 20px;
`;

export const ReportRangeContainer = styled.div`
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding-right: 2rem;
  transition: width 0.3s ease;

  @media (max-width: 900px) {
    padding-right: 1rem;
  }
`;

export const ReportRange = styled(CFormRange)`
  width: 150px;
  margin: 0 5px;
  padding: 0 5px;
  accent-color: #2ecc71;
`;

export const ReportRangeValue = styled.span`
  margin: 0 10px;
`;

export const ZoomReportButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;

export const ContainerReport = styled.div`
  margin-top: ${props => (props.isMobile && !props.portrait) ? '25px' : '0px'};
  position: relative;
  display: block;
`;

export const MenuLeft = styled.div`
  width: 250px;
  padding: 13px;
  border-right: 2px solid var(--color-grey);
`;

export const UsersGroup = styled.div`
  padding: 4px;
  font-weight: bold;
`;

export const UsersReport = styled.div`
  padding: 4px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
`;

export const ReportRangeContainerMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;