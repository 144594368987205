import { useContext, useEffect, useState } from 'react'
import { Context } from 'contexts/context'
import Topbar from 'components/Topbar'
import BtnOrange from 'components/Button/BtnOrange'
import LoadingScreen from 'components/LoadingScreen'
import { Body, Content, ContentWrapper, Notification, StyledTopbar } from './styled'
import BaseComponent from 'components/ComponentBase'
import { getUser } from 'services/auth'
import { FaWhatsapp } from 'react-icons/fa';
import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils'
import Notifications from 'components/Notification'
import TopBarPhone from 'components/TopBarPhone'

const ErrorComponent = () => {
    return (
        <div 
            style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' ,
                flexDirection: 'column',
                height: '70vh'
            }}
        >
            <h1 
                style={{ 
                    fontSize: '2rem', 
                    marginBottom: '1rem',
                    textAlign: 'center'
                }}
            >
                {/* TO CHANGE ATRIA INATIVO */}
                Sistema inativo no momento
                {/* Sistema em manutenção até 12/08/24 às 8h */}
            </h1>

            <BtnOrange
                onClick={() => window.location.reload(false)}
            >
                Tentar Novamente
            </BtnOrange>
        </div>
    )
}

const Base = ChildrenComponent => {
    const ComponentBase = props => {

        const { toggle, portrait, topBarShow, firm, setUser, showNotifications, report } = useContext(Context)
        const [isLoaded, setIsLoaded] = useState(false);

        useEffect(() => {
            const fetchUser = async () => {
                const data = await getUser()
                setUser(data || {});
            };

            fetchUser()
            if (firm) setIsLoaded(true)
        }, [])

        if (!firm) return null;

        return  (
            <BaseComponent>
                <Body>
                    {/* {portrait && <Topbar error={props.error} />} */}
                    {!portrait && report ? <TopBarPhone /> : <Topbar error={props.error} />}
                    
                        <ContentWrapper>
                                <Content toggle={toggle} topBarShow={topBarShow}>
                                    {props.error ? (
                                        <ErrorComponent />
                                    ) : (
                                        <>
                                        <ChildrenComponent {...props} />
                                        {isLoaded && showNotifications && <Notifications />}
                                        </>
                                    )}
                                    
                                </Content>
                        </ContentWrapper>   
                </Body>
            </BaseComponent>
        ) 
    }
    
    return ComponentBase
}

export default Base