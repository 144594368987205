import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleStoreUser } from 'services/api/users'
import Component from './Component'
import { handleStoreDepartment, handleUpdateDepartment } from 'services/api/department'
import { handleStoreCompany, handleUpdateCompany } from 'services/api/companies'
import { Context } from 'contexts/context'

const Page = () => {
    const [messages, setMessages] = useState({ messages: [], alert: '' })
    const history = useHistory()
    const { firm } = useContext(Context)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: { name: '', email: '', roles: '', department_id: '', company_id: '', blocked_user: false },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: async () => {
            setIsSubmitting(true)
            formik.values.department_id = formik.values.department_id.value
            formik.values.company_id = formik.values.company_id.value
            formik.values.roles = formik.values.roles.value
            handleStoreUser(history, formik.values, setMessages, firm)}
    })
    
    const handleResetForm = (formik) => {
        formik.resetForm();
      };
      

    const formikModal = useFormik({
        initialValues: { department: '' },
        onSubmit: async () => {
            handleStoreDepartment(formikModal.values, setMessages)
            handleResetForm(formikModal)
        }
        })

    const formikModalEdit = useFormik({
        initialValues: { department_id: '' },
        onSubmit: async () => {
            formikModalEdit.values.department_id = formikModalEdit.values.department_id.value
            handleUpdateDepartment(formikModalEdit.values, setMessages)
            handleResetForm(formikModalEdit)
        }
        })
        
    const formikModalCompany = useFormik({
        initialValues: { company: '' },
        onSubmit: async () => {
            handleStoreCompany(formikModalCompany.values, setMessages)
            handleResetForm(formikModalCompany)
            }
            })
    
    const formikModalCompanyEdit = useFormik({
        initialValues: { company_id: '' },
        onSubmit: async () => {
            formikModalCompanyEdit.values.company_id = formikModalCompanyEdit.values.company_id.value
            handleUpdateCompany(formikModalCompanyEdit.values, setMessages)
            handleResetForm(formikModalCompanyEdit)
            }
            })    

    return <Component 
            formik={formik} 
            formikModal={formikModal}
            formikModalEdit={formikModalEdit}
            formikModalCompany={formikModalCompany}
            formikModalCompanyEdit={formikModalCompanyEdit}
            messages={messages}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}/>
}

export default Page