import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import styled from 'styled-components'

export const Topbar = styled.div`
    height: 25px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 2rem;
    z-index: 1;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);

    @media (max-width: 900px) {
        // padding: 0 1rem;
    } 
`

export const IconsContainer = styled.div`
    margin-right: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 1rem;
`

export const ShowMobile = styled.div`
    display: none;
    align-items: center;
    // margin-left: 1rem;

    @media (max-width: 1000px) {
        display: flex;
    } 
`

export const Dropdown = styled(CDropdown)`
// margin-right: 150px;
`

export const DropdownToggle = styled(CDropdownToggle)`
    background-color: transparent !important;
    // background-color: red;
    // border-radius: 30px;
    border: none;
    transition: all 0.3s;
    // padding: 0px 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
    }

    &:focus {
        outline: 0;
    }

    &.dropdown-toggle::after {
        color: var(--color-orange);
    }
`

export const DropdownMenu = styled(CDropdownMenu)`
    transform: translate(0px, 19px) !important;
`

export const DropdownItem = styled(CDropdownItem)`
    background-color: white !important;
    cursor: pointer;

    &:hover {
        opacity: .5;
    }
`

export const DropdownText = styled.span`
    font-size: 13px;
    margin-left: .5rem;
    color: var(--color-orange);
`