import { AiOutlineReload } from 'react-icons/ai'
import { FaStar, FaMobileAlt } from 'react-icons/fa'

import { Context } from 'contexts/context'
import { useContext, useEffect, useState } from 'react'

import { MobileMenuStyle, IconTitle } from './styled'
import { getReportCardIdFromReportInfo, getReportsByReportCardId, handleUpdateFavoriteReport } from 'services/api/reports'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const MobileMenu = ({ changeLayoutType }) => {
    const { user, report } = useContext(Context)
    const [selectedReport, setSelectedReport] = useState(null)
    const [selectedReportCard, setSelectedReportCard] = useState(null)
    const {group_id, report_id, dataset_id} = useParams()

    
    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const fetchReportData = async () => {
            const reportCardId = await getReportCardIdFromReportInfo(group_id, report_id, dataset_id);
            if (isMounted) {
                const reports = await getReportsByReportCardId(reportCardId[0].id);
                if (isMounted) {
                    setSelectedReport(reports[0]);
                    setSelectedReportCard(reportCardId[0]);
                }
            }
        };

        fetchReportData();

        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [report]);

    const [isFavorite, setIsFavorite] = useState(false)

    useEffect(() => {
        if (selectedReport) {
            setIsFavorite(selectedReport.favorite)
        }
    }, [selectedReport])

    async function toggleFavorite (event) {
        event.preventDefault();
        event.stopPropagation();
        const newFavoriteStatus = !isFavorite;

        setIsFavorite(newFavoriteStatus)
        
        await handleUpdateFavoriteReport(selectedReport.id, newFavoriteStatus, selectedReportCard.id)
    }

    const handleRefresh = (event) => {
        event.preventDefault();
        console.log("Refresh clicked");
        report.refresh();
    };

    const handleChangeLayout = (event) => {
        event.preventDefault();
        console.log("Change layout clicked");
        changeLayoutType();
    };

    return (
        <MobileMenuStyle>
            <IconTitle onClick={handleRefresh} onTouchStart={handleRefresh}>
              <AiOutlineReload color="black" size={20}/>
              Atualizar
            </IconTitle>
            {user.roles !== 'admin' && (
              <IconTitle onClick={toggleFavorite} onTouchStart={toggleFavorite}>
                <FaStar 
                    size={20} 
                    style={{ color: isFavorite ? '#E9BC1B' : 'transparent', stroke: 'black', strokeWidth: isFavorite ? 0 : 50 }} />
                Favoritar
              </IconTitle>
            )}
            <IconTitle onClick={handleChangeLayout} onTouchStart={handleChangeLayout}>
                <FaMobileAlt color="black" size={20}/>
                Mobile
            </IconTitle>
        </MobileMenuStyle>
    )
}

export default MobileMenu