import { useContext, useEffect, useState } from 'react'
import { Topbar, DropdownItem, DropdownMenu, DropdownText, DropdownToggle, Dropdown, ShowMobile, IconsContainer } from './styled'
import { Context } from 'contexts/context'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AiOutlineMenu, AiOutlineReload } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import { getReportCardIdFromReportInfo, getReportsByReportCardId, handleUpdateFavoriteReport } from 'services/api/reports';
import { IconTitle } from 'components/MobileMenu/styled';


const TopBarPhone = () => {

    const { user, report } = useContext(Context)
    const [selectedReport, setSelectedReport] = useState(null)
    const [selectedReportCard, setSelectedReportCard] = useState(null)
    const history = useHistory()

    const {group_id, report_id, dataset_id} = useParams()

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const fetchReportData = async () => {
            const reportCardId = await getReportCardIdFromReportInfo(group_id, report_id, dataset_id);
            if (isMounted) {
                const reports = await getReportsByReportCardId(reportCardId[0].id);
                if (isMounted) {
                    setSelectedReport(reports[0]);
                    setSelectedReportCard(reportCardId[0]);
                }
            }
        };

        fetchReportData();

        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [report]);

    const [isFavorite, setIsFavorite] = useState(false)

    useEffect(() => {
        if (selectedReport) {
            setIsFavorite(selectedReport.favorite)
        }
    }, [selectedReport])

    async function toggleFavorite (event) {
        event.preventDefault();
        event.stopPropagation();
        const newFavoriteStatus = !isFavorite;

        setIsFavorite(newFavoriteStatus)
        
        await handleUpdateFavoriteReport(selectedReport.id, newFavoriteStatus, selectedReportCard.id)
    }

    return (
        <Topbar>
            <IconsContainer>
                {user.roles !== 'admin' && (
                    <IconTitle onClick={toggleFavorite}>
                    <FaStar color={isFavorite ? '#E9BC1B' : '#B4B4B4'} size={16}/>
                </IconTitle>
            )}
            <IconTitle onClick={() => report.refresh()}>
                <AiOutlineReload color="black" size={16}/>
                </IconTitle>
            
            <div >
             <ShowMobile>
                <Dropdown>
                    <DropdownToggle>
                        <AiOutlineMenu fontSize={14} color="black" />
                    </DropdownToggle>
                    
                    <DropdownMenu>
                        {user.roles === 'admin' ? (
                            <>
                                <DropdownItem onClick={() => history.push("/")}>
                                    {/* <FaIndustry fontSize={20} color="var(--color-orange)" /> */}
                                    <DropdownText>Relatórios</DropdownText>
                                </DropdownItem>

                                <DropdownItem onClick={() => history.push("/usuarios")}>
                                    <DropdownText>Usuários</DropdownText>
                                </DropdownItem>

                                <DropdownItem onClick={() => history.push("/cards")}>
                                    <DropdownText>Cards</DropdownText>
                                </DropdownItem>

                                <DropdownItem onClick={() => history.push("/colecoes")}>
                                    <DropdownText>Grupos</DropdownText>
                                </DropdownItem>
                            </>
                        ) : (
                            <DropdownItem onClick={() => history.push("/")}>
                                <DropdownText>Relatórios</DropdownText>
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </Dropdown>
                </ShowMobile>
            </div>
            </IconsContainer>
           
        </Topbar>
    )
}

export default TopBarPhone