import { useEffect, useState, useContext} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChartArea } from "react-icons/fa"
import { handlePowerBiReports } from 'services/api/powerbi'
import { handleFavoritesReportsByUser, handleRecentsReportsByUser, handleReportsByUserWithCard } from 'services/api/reports'
import { Context } from 'contexts/context'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
// import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Container from 'components/Container'
import ReportCard from 'components/ReportCard'
import api from 'services/api/api'
import InputWithSearchIcon from 'components/InputSearch'
import CardsFilters from 'components/CardsFilters'
import { CotainerReportCardCarousel, DepartmentContainer, DepartmentsContainer, GreyTippy, IconCards, InputCadastrar, InputIconsDiv, SelectDepartmentContainer, VerticalLine } from 'pages/Cards/styled'
import { FaAngleDown, FaAngleRight, FaAngleLeft  } from 'react-icons/fa'
import 'tippy.js/dist/tippy.css';
import { updateCardsOpen } from 'services/api/users'
import { getUser } from 'services/auth'
import { CardTitle, CardTitlep } from 'components/CardTitle/styled'
import { ReportCardCarousel } from 'components/CardsCarousel'

const Page = () => {
  
  const [data, setData] = useState([])
  const [filter, setFilter] = useState('');
  const [expandedContainers, setExpandedContainers] = useState({});
  const { user, setPainelTV } = useContext(Context)
  const [thisUser, setThisUser] = useState(null)
  const [expandedSelect, setExpandedSelect] = useState(null)
  const [recentsReports, setRecentsReports] = useState([])
  const [favoritesReports, setFavoritesReports] = useState([])
  const [activeTab, setActiveTab] = useState('');

  const params = useParams()

  const updateFavorites = async () => {
    const userData = await getUser();
    const updatedFavoritesReports = await handleFavoritesReportsByUser(userData.id);
    setFavoritesReports(updatedFavoritesReports);
  };

  useEffect(() => {
    (async () => {
      const userData = await getUser()

      try {
        if (userData.roles === 'admin') {
          setData(await handlePowerBiReports(params.group_id))
        } else {
          setActiveTab(userData.last_report_filter || 'recentes')
          setData(await handleReportsByUserWithCard())

          // Recents
          const recentsReports = await handleRecentsReportsByUser(userData.id)
          setRecentsReports(recentsReports)
         
          // Favorites
          const favoritesReports = await handleFavoritesReportsByUser(userData.id)
          setFavoritesReports(favoritesReports)

          const { data } = await api.get(`/carousels/by-user`)
          if (!data) setPainelTV(false)

          const dataCards = await handleReportsByUserWithCard()
          if (userData.cardsClosed) firstMinimizeAllGroups(dataCards)
          else firstExpandAllGroups(dataCards)
          
          setThisUser(userData)
          if(thisUser) setExpandedSelect(userData.cardsClosed)
        }
      } catch(error) {
        console.log(error)
      }
    })()
  }, [params.group_id, user.roles])

  function includesFilter(value, filter) {
    return value && value.toLowerCase().includes(filter.toLowerCase());
}

  const filteredData = data
    .filter(item =>
      includesFilter(item.name, filter) ||
      includesFilter(item.department_description, filter)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  
    const groupedData = filteredData.reduce((groups, item) => {
      const departmentId = item.department_description || 'null';
      if (!groups[departmentId]) {
        groups[departmentId] = [];
      }
      groups[departmentId].push(item);
      return groups;
    }, {});

    Object.keys(groupedData).forEach(departmentId => {
      groupedData[departmentId].sort((a, b) => a.name.localeCompare(b.name));
    });
    const departmentIds = Object.keys(groupedData);
    const sortedDepartments = departmentIds.filter(departmentId => departmentId !== "null").sort();
    const otherDepartment = departmentIds.find(departmentId => departmentId === "null");

    const sortedDepartmentIds = otherDepartment ? sortedDepartments.concat(otherDepartment) : sortedDepartments;
    const orderedDepartmentIds = sortedDepartmentIds.sort();

    const toggleExpansion = departmentId => {
      setExpandedContainers(prevState => ({
        ...prevState,
        [departmentId]: !prevState[departmentId]
      }));
    };
    
    async function expandAllGroups(reportCardsData){
      if(!expandedSelect) {
        setExpandedSelect(true)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = true;
        });
        setExpandedContainers(initialExpandedContainers)
        await updateCardsOpen(thisUser.id, {cardsClosed: 0})
      }
    }

    async function minimizeAllGroups(reportCardsData){
      if(expandedSelect) {
        setExpandedSelect(false)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = false;
        });
        setExpandedContainers(initialExpandedContainers);
        await updateCardsOpen(thisUser.id, {cardsClosed: 1})
      }
    }

    function firstExpandAllGroups(reportCardsData){
        setExpandedSelect(true)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = true;
        });
        setExpandedContainers(initialExpandedContainers)
    }

    function firstMinimizeAllGroups(reportCardsData){
        setExpandedSelect(false)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = false;
        });
        setExpandedContainers(initialExpandedContainers);
    }

    const handleNavClick = (tab) => {
      setActiveTab(tab);
      // Add logic to fetch data based on the active tab
    };

  return (
    <>
      <TitleBar label="Relatórios" currentPage=""/>

      <Content>
        <Card>
        {user.roles === 'admin' ?
        <>
          <CardTitle title="Meus Relatórios">
          <div style={{margin: '23px'}}></div>
            <InputCadastrar>
            
              <InputWithSearchIcon 
                type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
              </InputCadastrar>
          </CardTitle>

            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th width="50">Ações</th>
                </tr>
              </thead>

              <tbody>
                {filteredData.map(item =>
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    
                    <td>
                      <SmLinkBlue to={`/grupos/${params.group_id}/relatorios/${item.id}/datasets/${item.datasetId}`}>
                        <FaChartArea />
                      </SmLinkBlue>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            </> :
            ( typeof thisUser?.cardsClosed !== 'undefined' && (
            <>
            
              <CardTitle>

                <CardTitlep>
                  <CardsFilters 
                    onNavClick={handleNavClick} 
                    thisUser={thisUser}/> 
                </CardTitlep>

                <InputIconsDiv>
                  <InputCadastrar>
                    <InputWithSearchIcon 
                  type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                  <VerticalLine/>
                  <span>
                    <GreyTippy
                      content="Exibição grupos fechados">
                    <IconCards
                      onClick={() => minimizeAllGroups(data)}
                      src="/icon fechado.png" 
                      alt="Close Groups Icon" 
                      op={!expandedSelect}/>
                  </GreyTippy>
                  </span>
                  <span>
                    <GreyTippy
                      content="Exibição grupos abertos">
                    <IconCards
                      onClick={() => expandAllGroups(data)}
                      src="/icon - aberto.png" 
                      alt="Open Groups Icon" 
                      op={expandedSelect}/>
                  </GreyTippy>
                  </span>
                </InputCadastrar>
              </InputIconsDiv>
            </CardTitle>
            <Container>
            {activeTab === 'recentes'  && recentsReports.length > 0 && (
                <CotainerReportCardCarousel>
                  <ReportCardCarousel 
                    reportsCarousel={recentsReports}
                    updateFavorites={updateFavorites} />
                </CotainerReportCardCarousel>
              )}
              {activeTab === 'favoritos'  && favoritesReports.length > 0 && (
                <CotainerReportCardCarousel>
                  <ReportCardCarousel 
                  reportsCarousel={favoritesReports}
                  updateFavorites={updateFavorites} />
                </CotainerReportCardCarousel>
              )}
              {activeTab === 'meusAplicativos' && (
                <></>
              )}
              {/* Aqui adicionar componente de favoritos */}
              {orderedDepartmentIds.map(departmentId => (
                <DepartmentsContainer key={departmentId}>
                  {departmentId !== "null" ? (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>{departmentId}</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                  </SelectDepartmentContainer>
                  ) : (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>Outros</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                    </SelectDepartmentContainer>
                  )}
                  <DepartmentContainer style={{ maxHeight: expandedContainers[departmentId] ? 'none' : '0px', overflow: 'hidden' }}>
                    {groupedData[departmentId] && groupedData[departmentId].map(item => (
                      <ReportCard
                        key={item.id} 
                        name={item.name} 
                        fileUrl={item.file_url} 
                        linkTo={`/grupos/${item.group_id}/relatorios/${item.report_id}/datasets/${item.dataset_id}`}
                        reportId={item.id}
                        groupId={item.group_id}
                        reportCardId={item.report_card_id}
                        datasetId={item.dataset_id}
                        isfavorite={item.favorite === 1 ? true : false}
                        updateFavorites={updateFavorites}
                      />
                    ))}
                  </DepartmentContainer>
                </DepartmentsContainer>
              ))}
            
            </Container>
            </>
            )
          )}
        </Card>
      </Content>
    </>
  )
}

export default Page