import styled from "styled-components";

export const MobileMenuStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: grey;
  position: fixed;
  opacity: 0.7;
  width: 70vw;
  height: 7.5vh;
  bottom: 10px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; // Ajuste o valor conforme necessário para garantir que esteja acima do Report
`;

export const IconTitle = styled.div`
  padding: 0 12px;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
