import { useContext, useEffect, useState, useRef } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { BeautifulName } from 'beautiful-name'
import { BiLogOut, BiKey } from 'react-icons/bi'
import { AiOutlineUser, AiOutlineBarChart } from 'react-icons/ai'
import BtnOrange from 'components/Button/BtnOrange'
import { Context } from 'contexts/context'
import { logout } from 'services/auth'


import {
  Topbar, LeftSideContainer, NavigationContainer, NavigationLink,
  NavigationText, Logo, RightSideContainer, ActionsContainer, Divider,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  DropdownText, UserContainer, UserName, UserPhoto, ShowMobile, Dividers,
  BellIcon
} from './styled'
import api from 'services/api/api'

const Component = (props) => {
  const history = useHistory()
  const location = useLocation()

  const { user, report, firm, setShowNotifications, showNotifications } = useContext(Context)
  const [painel, setPainel] = useState(false)

  const bellIconRef = useRef(null); // Adicione esta linha

  useEffect(async() => {
    const { data } = await api.get(`/carousels/by-user`)
    if (data) setPainel(true)
  })

  const isActiveRoute = (route) => {
    return location.pathname.startsWith(route);
  };

  const NavigationTextWithDivider = ({ children }) => (
    <div>
      <NavigationText>{children}</NavigationText>
      <Dividers />
    </div>
  );

  const openNotification = () => {
    setShowNotifications(!showNotifications)
  }

  const userName = new BeautifulName(user.name).firstName
  const userImage = user.file_url ? `${process.env.REACT_APP_URL_API}/${user.file_url}` : '/user.svg'

  return (
    <Topbar>
      <LeftSideContainer>
        {/* TO CHANGE APPS */}
        {/* FIRM TO CHANGE */}
        <Link to='/'><Logo src={firm && firm.file_logo ? `${process.env.REACT_APP_URL_API}/${firm.file_logo}` : '/logo-nivel-3-2-dato.png'} alt={firm.market_name} /></Link>
        {/* <Link to='/'><Logo src={`/logos/logo-nivel-3-2-${firm.firm_name}.png`} alt={firm.company_name} /></Link> */}
        {/* <Link to='/'><Logo src="/logo-nivel-3-2.png" alt="Atria Lub" /></Link> */}
        {/* <Link to='/'><Logo src="/logo-nivel-3-2.png" alt="Ipel" /></Link> */}
        {/* <Link to='/'><Logo src="/logo-nivel-3-2.png" alt="Assescont BI" /></Link> */}

        <NavigationContainer>    
          <NavigationLink show style={{ display: "block !important" }} to="/">
                      
            {/* <FaIndustry fontSize={20} color="var(--color-orange)" /> */}
            {(isActiveRoute('/grupos') || isActiveRoute('/relatorios')) ? (
            <NavigationTextWithDivider>Relatórios</NavigationTextWithDivider> ) :
            (
              <NavigationText>Relatórios</NavigationText>
            )}
          </NavigationLink>

          {user.roles === 'admin' && (
            <>
              <NavigationLink to="/usuarios">
                {/* <FaUsers fontSize={20} color="var(--color-orange)" /> */}
                {isActiveRoute('/usuarios') ? (
                <NavigationTextWithDivider>Usuários</NavigationTextWithDivider> ) :
                (
                  <NavigationText>Usuários</NavigationText>
                )}
              </NavigationLink>

              <NavigationLink to="/cards">
                {/* <FaImages fontSize={20} color="var(--color-orange)" /> */}
                {isActiveRoute('/cards') ? (
                <NavigationTextWithDivider>Cards</NavigationTextWithDivider> ) :
                (
                  <NavigationText>Cards</NavigationText>
                )}
              </NavigationLink>

              <NavigationLink to="/colecoes">
                {/* <FaLayerGroup fontSize={20} color="var(--color-orange)" /> */}
                {isActiveRoute('/colecoes') ? (
                <NavigationTextWithDivider>Grupos</NavigationTextWithDivider> ) :
                (
                  <NavigationText>Grupos</NavigationText>
                )}
              </NavigationLink>

              {/* <NavigationLink to="/analytics">
                <FaChartArea fontSize={20} color="var(--color-orange)" />
                <NavigationText>Analytics</NavigationText>
              </NavigationLink> */}

              {/* <NavigationLink to="/capacidades"> */}
                {/* <FaLayerGroup fontSize={20} color="var(--color-orange)" /> */}
                {/* {isActiveRoute('/capacidades') || isActiveRoute('/capacidade')? (
                <NavigationTextWithDivider>Capacidades</NavigationTextWithDivider> ) :
                (
                  <NavigationText>Capacidades</NavigationText>
                )}
              </NavigationLink> */}
              <NavigationLink to="/carrosseis">
                {/* <FaTv fontSize={20} color="var(--color-orange)" /> */}
                {isActiveRoute('/carrosseis') ? (
                <NavigationTextWithDivider
                  
                >Painel TV</NavigationTextWithDivider> ) : 
                ( 
                  <NavigationText>Painel TV</NavigationText>
                )}
              </NavigationLink>
            </>
          )}
          {painel && (
              <NavigationLink to="/carrosseis">
                {/* <FaTv fontSize={20} color="var(--color-orange)" /> */}
                {isActiveRoute('/carrosseis') ? (
                <NavigationTextWithDivider
                  
                >Painel TV</NavigationTextWithDivider> ) : 
                ( 
                  <NavigationText>Painel TV</NavigationText>
                )}
              </NavigationLink> )}

              { ((user.access_report_custom === 1 || user.roles === 'admin') && user.firm_id !== 11) && ( // Changed from () to &&
              <NavigationLink to="/relatorio-task">
              {/* <FaTv fontSize={20} color="var(--color-orange)" /> */}
              {isActiveRoute('/relatorio-task') ? (
              <NavigationTextWithDivider
              >Relatório Task</NavigationTextWithDivider> ) : 
              ( 
                <NavigationText>Relatório Task</NavigationText>
              )}
            </NavigationLink> 
          )}


        </NavigationContainer>

        {user.roles === 'admin' && (<ShowMobile>
          <Dropdown>
            <DropdownToggle>
              <span style={{ color: "var(--color-orange)", marginRight: ".5rem"  }}>Menu</span>
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem onClick={() => history.push("/")}>
                {/* <FaIndustry fontSize={20} color="var(--color-orange)" /> */}
                <DropdownText>Relatórios</DropdownText>
              </DropdownItem>

              <DropdownItem  onClick={() => history.push("/usuarios")}>
                {/* <FaUsers fontSize={20} color="var(--color-orange)" /> */}
                <DropdownText>Usuários</DropdownText>
              </DropdownItem>

              <DropdownItem  onClick={() => history.push("/cards")}>
                {/* <FaImages fontSize={20} color="var(--color-orange)" /> */}
                <DropdownText>Cards</DropdownText>
              </DropdownItem>

              <DropdownItem  onClick={() => history.push("/colecoes")}>
                {/* <FaLayerGroup fontSize={20} color="var(--color-orange)" /> */}
                <DropdownText>Grupos</DropdownText>
              </DropdownItem>

              {/* <DropdownItem  onClick={() => history.push("/analytics")}>
                <FaChartArea fontSize={20} color="var(--color-orange)" />
                <DropdownText>Analytics</DropdownText>
              </DropdownItem> */}
            </DropdownMenu>
          </Dropdown>
        </ShowMobile>
        ) }
      </LeftSideContainer>

      <RightSideContainer>
      
      {report && (
          <>
            <ActionsContainer>
              <BtnOrange
                onClick={() => report.fullscreen()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  marginRight: ".5rem",
                  padding: 0
                }}
              >
                <img
                  src="/svgs/fullscreen.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange>

              {/* <BtnOrange
                onClick={() => report.print()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  marginRight: ".5rem",
                  padding: 0
                }}
              >
                <img
                  src="/svgs/print.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange> */}

              <BtnOrange
                onClick={() => report.refresh()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  padding: 0
                }}
              >
                <img
                  src="/svgs/autorenew.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange>
            </ActionsContainer>

            <Divider />
          </>
        )}
        {/* {firm.id === 20 ? (
          <div style={{ position: 'relative', marginRight: "20px" }}>
            <BellIcon ref={bellIconRef} onClick={openNotification} disabled={showNotifications}/>
            {1 > 0 && (
              <span style={{ 
                position: 'absolute', 
                top: '-3px', 
                right: '-4px', 
                background: 'red', 
                color: 'white', 
                borderRadius: '50%', 
                padding: '1px 4px', 
                fontSize: '9px' 
              }}>
                1
              </span>
            )}
          </div>
        ) : <></>} */}

        <Dropdown>
          <DropdownToggle>
            <UserContainer>
              <UserName>Olá, {userName}!</UserName>
              <UserPhoto src={userImage} alt={`Foto de ${userName}`} />
            </UserContainer>
          </DropdownToggle>

          <DropdownMenu>
            {user.roles !== 'admin' && (
              <DropdownItem onClick={() => history.push("/")}>
                <AiOutlineBarChart fontSize={20} color="var(--color-orange)" />
                  <DropdownText>Relatórios</DropdownText>
                </DropdownItem>
              )}
                <DropdownItem onClick={() => history.push("/profile")}>
                  <AiOutlineUser fontSize={20} color="var(--color-orange)" />
                <DropdownText>Perfil</DropdownText>
              </DropdownItem>

              <DropdownItem onClick={() => logout(history)}>
                <BiLogOut fontSize={20} color="var(--color-orange)" />
                  <DropdownText>Sair</DropdownText>
                </DropdownItem>
              
            

          </DropdownMenu>
        </Dropdown>
      </RightSideContainer>
    </Topbar>
  )
}

export default Component