import { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'
import { AiOutlinePlus, AiOutlineMinus, AiOutlineReload } from 'react-icons/ai'
import { FaStar } from 'react-icons/fa'
import { MdZoomOutMap } from 'react-icons/md'
import { models, factories, service } from 'powerbi-client';
import { useDebouncedCallback } from 'use-debounce'
import M from 'materialize-css';
import { Context } from 'contexts/context'
import { handleReport, handleReportsByUserWithCard } from 'services/api/reports'
import { handleCreateUserActionReport, handleUpdateUserActionReport } from 'services/api/usersActionsReports'
import {  handlePowerBiReportEmbed } from 'services/api/powerbi'

import { isMobile } from 'react-device-detect';

import { PowerBIEmbed } from 'powerbi-client-react'

import {
  ReportRangeContainer,
  ReportRange,
  ReportRangeValue,
  ZoomReportButton,
  ContainerReport,
  ReportRangeContainerMobile,
} from './style.jsx';
import MobileMenu from 'components/MobileMenu/index.jsx'

const Page = () => {
  const history = useHistory()
  const [reportsData, setReportsData] = useState([])
  const powerBiRef = useRef(null)
  const reportContainerRef = useRef(null);
  const [layoutType, setLayoutType] = useState(null)
  const [hasMobileLayout, setHasMobileLayout] = useState(false);
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  const checkInactivity = () => {
    const expireTime = localStorage.getItem("expireTime")

    if (expireTime < Date.now()){
        if (user.roles === 'admin') {
          history.push('/grupos')
        } else {
          history.push('/relatorios')
        }
    }
}

  const updateExpireTime = () => {
    const expireTime = Date.now() + 1200000;
    localStorage.setItem("expireTime", expireTime)
  }

  useEffect(() =>{
    const interval = setInterval(() => {
        checkInactivity()
    }, 60000)
    
    return () => clearInterval(interval)
}, [])

  useEffect(() =>{
      updateExpireTime()

      window.addEventListener("click", updateExpireTime)
      window.addEventListener("keypress", updateExpireTime)
      window.addEventListener("scroll", updateExpireTime)
      window.addEventListener("mousemove", updateExpireTime)

      return () => {
      window.removeEventListener("click", updateExpireTime)
      window.removeEventListener("keypress", updateExpireTime)
      window.removeEventListener("scroll", updateExpireTime)
      window.removeEventListener("mousemove", updateExpireTime)
      }
  },[])

  const [data, setData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [userActionReportId, setUserActionReportId] = useState()

  const [isFitToPage, setIsFitToPage] = useState(true)
  const [rangeValue, setRangeValue] = useState(50)

  const debouncedRangeValue = useDebouncedCallback(
    async (value) => {
      setRangeValue(Number(value))
      await report.setZoom((value * 0.039) + 0.1)
    },
    500
  )

  const params = useParams()


  const { user, report, setReport, portrait, setPortrait, setTopBarShow } = useContext(Context)
  const { group_id, report_id, dataset_id } = params
  const screenOrientation = screen.orientation

  const getData = async () => {
    if (user.roles === 'admin') { 

      // const reportData = await handlePowerBiReportAad({ group_id, report_id, dataset_id })
      
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        // roles: ''
      })
      // setData({
      //   reportId: reportData.reportId,
      //   embedUrl: reportData.embedUrl,
      //   token: reportData.accessToken,
      //   // type: models.TokenType.Aad
      //   type: models.TokenType.Embed
      // })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.accessToken;
    }

    const roles = await handleReport(report_id, user.id)

    if (roles.roles) {
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        roles: roles.roles
      })

      handleUserAction(roles.report_card_id)

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        page_navigation: roles.page_navigation,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.embedToken;
    }

    // const reportData = await handlePowerBiReportAad({ group_id, report_id })

    // handleUserAction(roles.report_card_id)

    // setData({
    //   reportId: reportData.reportId,
    //   embedUrl: reportData.embedUrl,
    //   token: reportData.accessToken,
    //   page_navigation: roles.page_navigation,
    //   type: models.TokenType.Aad
    // })

    const reportData = await handlePowerBiReportEmbed({
      group_id,
      report_id,
      dataset: dataset_id,
      username: user.email,
      // roles: roles.roles
    })

    handleUserAction(roles.report_card_id)

    setData({
      reportId: reportData.reportId,
      embedUrl: reportData.embedUrl,
      token: reportData.embedToken,
      page_navigation: roles.page_navigation,
      type: models.TokenType.Embed,
      expiration: reportData.embedToken.expiration
    })

    return reportData.embedToken;
  }

  async function fetchData() {
    if (user) getData()
    setReportsData(await handleReportsByUserWithCard())
  }

 
  const handleUserAction = async (report_card_id) => {
    const userActionReportResponse =
      await handleCreateUserActionReport({ report_id: report_card_id })

    setUserActionReportId(userActionReportResponse)
  }

  useBeforeunload(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }
  })

  const unblock = history.block(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }

    setReport(null)
    unblock()
  })

  // if (window.screen) {
  //    window.screen.orientation.onchange = event => {
        
  //        if (event.target.type === "landscape-primary") {
  //          report.fullscreen()

  //          return
  //        }

  //        if (document.fullscreenElement) report.exitFullscreen()
  //      }

  // } else if (screen.orientation) {
  //   screen.orientation.addEventListener("change", event => {
  //   if (event.target.type === "landscape-primary") {
  //       report.fullscreen();
  //       return;
  //   }

  //   if (document.fullscreenElement) {
  //       report.exitFullscreen();
  //   }

  const width = window.innerWidth;
  const height = window.innerHeight;

  // useEffect(() => {
  //   console.log(width, height, 'width, height')
  //   if (width < 1000 && height < 1000) setIsMobile(true)
  //     else setIsMobile(false)
  // }, [width, height, report])

    const [minHeight, setMinHeight] = useState('100dvh');

    
    useEffect(() => {
      if (width < 1000 && height < 1000) setIsMobileLayout(true)
      else setIsMobileLayout(false)

      if ((screenOrientation.type === "landscape-primary" || screenOrientation.type === "landscape-secondary") && isMobileLayout) {
        setPortrait(false)
        setTopBarShow(false)
        setIsFitToPage(false)
        setMinHeight('100dvh')
      }
      else {
        setPortrait(true)
        setTopBarShow(true)
        setIsFitToPage(true)
        setMinHeight('calc(100dvh - 90px)')
        }
    },[report, report_id, width, height])

  if (report) {
    screenOrientation.addEventListener("change", async event => {
      const containerReport = document.querySelector('.containerReport')

      if (report.fullscreen || report.exitFullscreen) {
        if ((event.target.type === "landscape-primary" || event.target.type === "landscape-secondary")) {
          setPortrait(false);
          setTopBarShow(false);
          setIsFitToPage(false);
        if (containerReport) containerReport.style.minHeight = '100dvh'
          else setMinHeight('100dvh')
        } else {
          setPortrait(true);
          setTopBarShow(true);
          setIsFitToPage(true);
        if (containerReport) containerReport.style.minHeight = 'calc(100dvh - 90px)'
          else setMinHeight('calc(100dvh - 90px)')
        }
      }
    });
  } else {
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems);
    });

    const handleWindowSizeChange = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width > height && report) {
        report.fullscreen();
      } else if (report) {
        report.exitFullscreen();
      }
    };

    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
  }

  // const checkMobileLayout = async () => {
  //   console.log('checkMobileLayout')
  //   console.log(report, 'report')
  //   if (report) {
  //     const pages = await report.getPages();
  //     const mobileLayoutAvailable = await pages[0].hasLayout(models.LayoutType.MobilePortrait);
      
  //     setHasMobileLayout(mobileLayoutAvailable);
  //     console.log(mobileLayoutAvailable, 'mobileLayoutAvailable')
  //     console.log(isMobile, 'isMobile')
  //     console.log(portrait, 'portrait')
  //     if (mobileLayoutAvailable && isMobile && portrait) {
  //       changeToMobileLayout()
  //     }
  //   }
  // };

    const checkMobileLayout = async () => {
      if (report) {
        const pages = await report.getPages();
        const mobileLayoutAvailable = await pages[0].hasLayout(models.LayoutType.MobilePortrait);
        setHasMobileLayout(mobileLayoutAvailable);
        console.log(mobileLayoutAvailable, 'mobileLayoutAvailable')
      }
    };

// if (user.roles === 'admin') {
// embedConfig = {

//   type: 'report',
//   id: data.reportId,
//   embedUrl: data.embedUrl,
//   // comentar no GENERATE TOKEN
//   eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
//   accessToken: data.token,
//   permissions: models.Permissions.All,
//   tokenType: data.type || 
//   models.TokenType.Aad, 
//   // GENERATE TOKEN 
//   // models.TokenType.Embed,
//   settings: {
//     layoutType: models.LayoutType.Custom,
//     customLayout: {
//       displayOption: models.DisplayOption[`${isFitToPage ? "FitToPage" : "FitToWidth"}`],
//     },
//     panes: {
//       filters: {
//         visible: false
//       },
//       pageNavigation: {
//         visible: data.page_navigation ? data.page_navigation : false
//       }
//     }
//   }
// }
// } else {
  let embedConfig = {
      type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    // comentar no GENERATE TOKEN
    eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
    accessToken: data.token,
    permissions: models.Permissions.All,
    tokenType: data.type || 
    models.TokenType.Aad, 
    // GENERATE TOKEN 
    // models.TokenType.Embed,
    settings: {
      // check aqui
      layoutType: (isMobile && hasMobileLayout) && portrait ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption[`${isFitToPage ? "FitToPage" : "FitToWidth"}`],
      },
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false
        }
      }
    }
  }

// }
  const handleOnChange = (event) => {
    debouncedRangeValue(event.target.value)
  }

  const handleFit = () => {
    setIsFitToPage(!isFitToPage)
  }

  const handlePlusZoom = async () => {
    if (rangeValue < 100) {
      const newRangeValue = rangeValue + 5 > 100 ? 100 : rangeValue + 5
      setRangeValue(newRangeValue)

      await report.setZoom((newRangeValue * 0.039) + 0.1)
    }
  }

  const handleMinusZoom = async () => {
    if (rangeValue > 0) {
      const newRangeValue = rangeValue - 5 < 0 ? 0 : rangeValue - 5
      setRangeValue(newRangeValue)

      await report.setZoom((newRangeValue * 0.039) + 0.1)
    }
  }

  useEffect(() => {
    if (isLoaded) report?.getZoom().then(response => setRangeValue(Number(((response - 0.1) / 0.039).toFixed(0))))
  }, [isFitToPage, isLoaded])

  const selectReport = async (embedObject) => {
    await setReport(embedObject);
  };

  useEffect(async () => {
    fetchData()
    // resetEmbed()
  }, [user, report_id])

  // const resetEmbed = () => {
  //   setEmbedKey(report_id);
  // };

  useEffect(() => {
    console.log(hasMobileLayout, 'hasMobileLayout atualizado');
  }, [hasMobileLayout]);

  const changeLayoutType = async () => {
      const pages = await report.getPages();
      const mobileLayoutAvailable = await pages[0].hasLayout(models.LayoutType.MobilePortrait);
      setHasMobileLayout(mobileLayoutAvailable);
      console.log(mobileLayoutAvailable, 'mobileLayoutAvailable')

    console.log(mobileLayoutAvailable, 'mobileLayoutAvailable')
    console.log(isMobile, 'isMobile')
    console.log(layoutType, 'layoutType')

    if (mobileLayoutAvailable && isMobile) {
      if (!portrait) {
        if (layoutType === models.LayoutType.MobilePortrait) {
          console.log('não é retrato e modelo é mobile')
          setMinHeight('100dvh')
          changeLayout(models.LayoutType.Custom)
          setLayoutType(models.LayoutType.Custom)
          
        } else {
          console.log('não é retrato e modelo é custom')
          setMinHeight('calc(100dvh - 90px)')
          changeLayout(models.LayoutType.MobilePortrait)
          setLayoutType(models.LayoutType.MobilePortrait)
          
        }
      } else {
        console.log('é retrato -> necessariamente quero que seja custom')
        setMinHeight('100dvh')
        changeLayout(models.LayoutType.Custom)
        setLayoutType(models.LayoutType.Custom)
      }
    }
  }

      const changeLayoutTypeButton = async () => {
        const pages = await report.getPages();
        const mobileLayoutAvailable = await pages[0].hasLayout(models.LayoutType.MobilePortrait);
        setHasMobileLayout(mobileLayoutAvailable);
        console.log(mobileLayoutAvailable, 'mobileLayoutAvailable')

        if (mobileLayoutAvailable && isMobile) {
    
          if (portrait) {
            if (layoutType === models.LayoutType.MobilePortrait) {
              setMinHeight('100dvh')
              changeLayout(models.LayoutType.Custom)
              setLayoutType(models.LayoutType.Custom)
              
            } else {
              setMinHeight('calc(100dvh - 90px)')
              changeLayout(models.LayoutType.MobilePortrait)
              setLayoutType(models.LayoutType.MobilePortrait)
              
            }
          } else {
            setMinHeight('100dvh')
            changeLayout(models.LayoutType.Custom)
            setLayoutType(models.LayoutType.Custom)
            
          }
        }
      }
      
      
    //   if (layoutType === models.LayoutType.MobilePortrait && portrait) {
    //   changeLayout(models.LayoutType.Custom)
    //   setLayoutType(models.LayoutType.Custom)
    //   // containerReport.style.minHeight = 'calc(100dvh - 90px)'
    //   setMinHeight('100dvh')
      
    // } else if (layoutType === models.LayoutType.Custom && !portrait) {
    //   changeLayout(models.LayoutType.MobilePortrait)
    //   setLayoutType(models.LayoutType.MobilePortrait)
    //   // containerReport.style.minHeight = '100dvh'
    //   setMinHeight('calc(100dvh - 90px)')

    // } else if (layoutType === models.LayoutType.Custom && portrait) {
    //   changeLayout(models.LayoutType.MobilePortrait)
    //   setLayoutType(models.LayoutType.MobilePortrait)
    //   // containerReport.style.minHeight = '100dvh'
    //   setMinHeight('calc(100dvh - 90px)')
    // }

  // const changeToMobileLayout = () => {
  //   changeLayout(models.LayoutType.MobilePortrait)
  //   setLayoutType(models.LayoutType.MobilePortrait)
  //   setMinHeight('calc(100dvh - 90px)')
  // }

  const changeLayout = async (layoutType) => {
    try {
      // Verifique se o report está inicializado
      if (!report) {
        console.log('Report is not initialized.');
        return;
      }

      // Crie uma nova instância do serviço Power BI
      const powerbiService = new service.Service(
        factories.hpmFactory,
        factories.wpmpFactory,
        factories.routerFactory
      );

      if (reportContainerRef.current) {
        powerbiService.reset(reportContainerRef.current);

        // Defina a nova configuração de embed


        // se o layout for mobile, então o displayOption é FitToPage
        const newEmbedConfig = {
          ...embedConfig,
          settings: {
            ...embedConfig.settings,
            layoutType: layoutType,
            customLayout: {
              // displayOption: models.DisplayOption[`${isFitToPage ? "FitToPage" : "FitToWidth"}`],
              // displayOption: models.DisplayOption.FitToWidth,
              // se o layout for mobile, então o displayOption é FitToPage
              displayOption: models.DisplayOption[`${portrait ? "FitToWidth" : "FitToPage"}`],
            }
          }
        };
        // Re-embed o relatório com a nova configuração
        const newReport = powerbiService.embed(reportContainerRef.current, newEmbedConfig);

        // Atualize a referência do relatório
        setReport(newReport);

      } else {
        console.error('Report container is not available.');
      }
    } catch (error) {
      console.error('Error updating report settings:', error);
    }
  };


  useEffect(() => {
    const handleOrientationChange = async () => {
      if (report) {
        const pages = await report.getPages();
        const hasMobileLayout = await pages[0].hasLayout(models.LayoutType.MobilePortrait);

        console.log(hasMobileLayout, 'hasMobileLayout')
        console.log(isMobile, 'isMobile')

        if (hasMobileLayout && isMobile) {
          changeLayoutType();
        }
      }
    };

    // Add event listener for orientation change
    screenOrientation.addEventListener("change", handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      screenOrientation.removeEventListener("change", handleOrientationChange);
    };
  }, [report, layoutType]);
  

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {/* <MenuReport
          history={history}
          reportsData={reportsData}
          resetEmbed={resetEmbed}
        /> */}
      <ContainerReport
        isMobile={isMobile}
        portrait={portrait}
        ref={reportContainerRef}
        style={{ minHeight: minHeight, width: '100%' }}
      >
        <PowerBIEmbed
          // key={embedKey}
          ref={powerBiRef}
          eventHandlers={
            new Map([
              ['loaded', async () => {
                console.log("Report loaded")
                setLayoutType(powerBiRef.current.props.embedConfig.settings.layoutType)
                
              }],
              ['rendered', async () => {
                setIsLoaded(true)
                // let fiberNodeKey = Object.keys(reportContainerRef.current).find(key => key.startsWith('__reactFiber$'));

                // let fiberNode = reportContainerRef.current[fiberNodeKey];
                // const reactPropsKeys = Object.keys(fiberNode.stateNode).filter(key => key.startsWith('__reactProps'));
                // const secondReactProps = fiberNode.stateNode[reactPropsKeys[0]].children.ref.current.containerRef.current.powerBiEmbed;
                // console.log('Second __reactProps:', secondReactProps);
          
                // const pages = await secondReactProps.getPages();
                // const mobileLayoutAvailable = await pages[0].hasLayout(models.LayoutType.MobilePortrait);
                // setHasMobileLayout(mobileLayoutAvailable);

                // if (mobileLayoutAvailable && isMobile && portrait) {
                //   changeLayout(models.LayoutType.MobilePortrait)
                // }
              }],
              ['error', event => console.log(event.detail)],
              ['buttonClicked', () => updateExpireTime()],
              ['visualClicked', () => updateExpireTime()],
              ['selectionChanged', () => updateExpireTime()],
              ['commandTriggered', () => updateExpireTime()],
              ['dataSelected', () => updateExpireTime()],
              ['pageChanged', () => updateExpireTime()],
              ['dataHyperlinkClicked', () => updateExpireTime()],
            ])
          }
          getEmbeddedComponent={async (embedObject) => {
            console.log('getEmbeddedComponent', embedObject)
            await selectReport(embedObject)
          }}
          embedConfig={embedConfig}
          cssClassName={"report"}
        />
      </ContainerReport>
    </div>
    {isLoaded && (
      <>
        {portrait && !isMobile && (
          <ReportRangeContainer>
            <ZoomReportButton onClick={handleMinusZoom}>
              <AiOutlineMinus />
            </ZoomReportButton>

            <ReportRange steps={1} value={rangeValue} onChange={handleOnChange} />

            <ZoomReportButton onClick={handlePlusZoom}>
              <AiOutlinePlus />
            </ZoomReportButton>

            <ReportRangeValue>{rangeValue}%</ReportRangeValue>

            <ZoomReportButton onClick={handleFit}>
              <MdZoomOutMap />
            </ZoomReportButton>
          </ReportRangeContainer>
        )}

        {portrait && isMobile && (
          // <MobileMenu>
          //   <IconTitle onClick={() => report.refresh()}>
          //     <AiOutlineReload color="black" size={20}/>
          //     Atualizar
          //   </IconTitle>
          //   {user.roles !== 'admin' && (
          //     <IconTitle>
          //       <FaStar color="black" />
          //       Favoritar
          //     </IconTitle>
          //   )}
          // </MobileMenu>
          <MobileMenu changeLayoutType={changeLayoutTypeButton}/>
        )}
      </>
    )}
  </>
)
}

export default Page
